var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix finished" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("产品基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "产品编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productCode ? _vm.ruleForm.productCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.insideCode ? _vm.ruleForm.insideCode : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品条形码" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productBarcode
                      ? _vm.ruleForm.productBarcode
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productName ? _vm.ruleForm.productName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.supplierName ? _vm.ruleForm.supplierName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "业务分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.businessType,
                      this.busiCategorytypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productClassification,
                      this.ProductClasstypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品系列" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productSeries ? _vm.ruleForm.productSeries : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品特性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productSeason,
                      this.seasontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品描述" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.specification ? _vm.ruleForm.specification : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "销售季" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.sellSeason,
                      this.SaleSeasoutypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品区分" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productDistinguish,
                      this.distinguishtypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "销售计量单位" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.measurementUnit,
                      this.SaleUnittypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "颜色" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.typeHandle(_vm.ruleForm.color, this.colortypelist))
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "上市日期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.listingDate ? _vm.ruleForm.listingDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "长", prop: "length" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.length ? _vm.ruleForm.length : ""))
              ]),
              _vm._v("  米\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.lifeCycle ? _vm.ruleForm.lifeCycle : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "宽" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.width ? _vm.ruleForm.width : ""))
              ]),
              _vm._v("  米\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "产品状态" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productStatus,
                      this.statustypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "高" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.height ? _vm.ruleForm.height : ""))
              ]),
              _vm._v("  米\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "是否活跃" } }, [
              _vm.ruleForm.active
                ? _c("span", [
                    _vm._v(_vm._s(_vm.ruleForm.active == true ? "是" : "否"))
                  ])
                : _c("span")
            ]),
            _c("h2", { staticClass: "title" }, [_vm._v("成品产品图文信息")]),
            _c(
              "el-form-item",
              { staticClass: "photo", attrs: { label: "缩略图" } },
              _vm._l(_vm.fileList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "img_wrap inline_block mr10" },
                  [
                    _c("img", {
                      staticClass: "img_upload",
                      attrs: { src: item.url ? item.url : "", alt: "" }
                    })
                  ]
                )
              }),
              0
            ),
            _c(
              "el-form-item",
              {
                staticClass: "last",
                attrs: { label: "成品产品详细信息", prop: "remarks" }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.ruleForm.remarks ? _vm.ruleForm.remarks : "")
                  )
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }