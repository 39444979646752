var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaintain" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productCode,
                        callback: function($$v) {
                          _vm.productCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.orderList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        cloneHandle: _vm.cloneHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _vm.actionsType == "check"
              ? _c(
                  "div",
                  [
                    _c("virtualMaintainCheck", {
                      attrs: {
                        list: _vm.ruleForm,
                        uploadFileList: _vm.uploadFileList
                      },
                      on: { cancelHandle: _vm.resetForm }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "base_wrap clearfix" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("产品基本信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品编号", prop: "productCode" }
                            },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatProductClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupProductCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.productCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "系统编号" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.ruleForm.productId))])
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称", prop: "productName" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.productName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "业务分类", prop: "businessType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.businessType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "businessType",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.businessType"
                                  }
                                },
                                _vm._l(this.busiCategorytypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上市日期", prop: "validDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsStart,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.validDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "validDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.validDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "生命周期", prop: "invalidDate" }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsEnd,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.invalidDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "invalidDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.invalidDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品状态",
                                prop: "productStatus"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productStatus",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productStatus"
                                  }
                                },
                                _vm._l(this.statustypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              ref: "typerules",
                              attrs: {
                                label: "产品分类",
                                prop: "productClassification"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.departChange },
                                  model: {
                                    value: _vm.ruleForm.productClassification,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productClassification",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productClassification"
                                  }
                                },
                                _vm._l(this.ProductClasstypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否活跃" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.active,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "active",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.active"
                                  }
                                },
                                _vm._l(this.ifStylelist, function(i) {
                                  return _c("el-option", {
                                    key: i.ifbatch,
                                    attrs: { value: i.ifbatch, label: i.name }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "last",
                              attrs: { label: "备注", prop: "remarks" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 2 },
                                  placeholder: "多行输入",
                                  maxlength: "255",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.ruleForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "remarks", $$v)
                                  },
                                  expression: "ruleForm.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          on: {
                            click: function($event) {
                              return _vm.resetForm()
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            loading: this.loadingcommit
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ])
          ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条虚拟产品数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }