var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("产品基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "产品编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productCode ? _vm.ruleForm.productCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.interiorCode ? _vm.ruleForm.interiorCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品条形码" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productBarcode
                      ? _vm.ruleForm.productBarcode
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productName ? _vm.ruleForm.productName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品描述" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.specification ? _vm.ruleForm.specification : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "业务分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.businessType,
                      this.busiCategorytypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品品牌" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.brand ? _vm.ruleForm.brand : ""))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品成分" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productComposition
                      ? _vm.ruleForm.productComposition
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品属性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productClass,
                      this.ProductClasstypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品系列" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productSeries ? _vm.ruleForm.productSeries : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "品类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.category, this.categorytypelist)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品特性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.season, this.seasontypelist)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品区分" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productDistinguish,
                      this.distinguishtypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "销售计量单位" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.saleMeasurementUnit,
                      this.SaleUnittypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "时尚度" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.fashion,
                      this.ProductFashionDegreetypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "延米克重" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.weightPerMeterShow
                      ? _vm.ruleForm.weightPerMeterShow
                      : ""
                  )
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "销售季" } }, [
              _c("span", [_vm._v(_vm._s(_vm.ruleForm.saleSeasou))])
            ]),
            _c("el-form-item", { attrs: { label: "每平米克重" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.weightPerSquareMeter
                      ? _vm.ruleForm.weightPerSquareMeter
                      : ""
                  )
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "颜色" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.typeHandle(_vm.ruleForm.color, this.colortypelist))
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "每个销售单位的物料数量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.numbersPerMeasurementUnit
                      ? _vm.ruleForm.numbersPerMeasurementUnit
                      : ""
                  )
                )
              ]),
              _vm._v("  \n        ")
            ]),
            _c("el-form-item", { attrs: { label: "花型" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.pattern, this.patterntypelist)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "幅宽" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.width ? _vm.ruleForm.width : ""))
              ]),
              _vm._v("  \n        ")
            ]),
            _c("el-form-item", { attrs: { label: "产地" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.placeOfProduction,
                      this.placeProductiontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "纱支" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.yarnCount ? _vm.ruleForm.yarnCount : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "上市日期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.validDate ? _vm.ruleForm.validDate : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.invalidDate ? _vm.ruleForm.invalidDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "推荐替代品" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.substitute ? _vm.ruleForm.substitute : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "团购推荐指数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.suggestIndex,
                      this.suggestIndexTypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品状态" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productStatus,
                      this.statustypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "是否活跃" } }, [
              _vm.ruleForm.active
                ? _c("span", [
                    _vm._v(_vm._s(_vm.ruleForm.active == true ? "是" : "否"))
                  ])
                : _c("span")
            ]),
            _c("h2", { staticClass: "title" }, [_vm._v("面料产品图文信息")]),
            _c(
              "el-form-item",
              { staticClass: "photo", attrs: { label: "缩略图" } },
              _vm._l(_vm.fileList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "img_wrap inline_block mr10" },
                  [
                    _c("img", {
                      staticClass: "img_upload",
                      attrs: { src: item.url ? item.url : "", alt: "" }
                    })
                  ]
                )
              }),
              0
            ),
            _c(
              "el-form-item",
              {
                staticClass: "last",
                attrs: { label: "面料产品详细信息", prop: "remarks" }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.ruleForm.remarks ? _vm.ruleForm.remarks : "")
                  )
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }