var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("产品基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: { inline: true, model: _vm.ruleForm, "label-width": "200px" }
          },
          [
            _c("el-form-item", { attrs: { label: "产品编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productCode ? _vm.ruleForm.productCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.productId ? _vm.ruleForm.productId : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.productName ? _vm.ruleForm.productName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "业务分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.businessType,
                      this.busiCategorytypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "上市日期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.validDate ? _vm.ruleForm.validDate : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.invalidDate ? _vm.ruleForm.invalidDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品状态" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productStatus,
                      this.statustypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productClassification,
                      this.ProductClasstypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "是否活跃" } }, [
              _vm.ruleForm.active != null
                ? _c("span", [
                    _vm._v(_vm._s(_vm.ruleForm.active == true ? "是" : "否"))
                  ])
                : _c("span")
            ]),
            _c(
              "el-form-item",
              { staticClass: "last", attrs: { label: "备注" } },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      height: "auto",
                      "word-wrap": "break-word",
                      "word-break": "break-all",
                      overflow: "hidden",
                      "line-height": "30px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.ruleForm.remarks))]
                )
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }