<template>
  <div class="container fabricMaintain">
    <div v-if="!isEdit">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">产品编号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="productCode" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">更新人：</span>
            <el-input class="input_single w200 mr10" v-model.trim="updateUser" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
              <span class="tag">更新日期：</span>
              <el-date-picker
                v-model="dateVal"
                unlink-panels
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateValChange"
              ></el-date-picker>
          </div>
        </div>
        
        <div class="actions_btn_wrap down t_right">
          <!-- <el-button type="primary" class="mr10" @click="exportHandle" v-preventReClick :disabled="this.isLoadingExport">导出</el-button>
          <el-upload :headers="myHeaders" class="upload-demo inline_block mr10" :action="this.uploadurlProduct"  accept=".xlsx" 
            :before-upload="beforeupload" :show-file-list="showfilelist" :data="{'businessType':'Retail,Suits','applicantName':this.$store.state.realName}" :on-success="UploadSuccess">
            <el-button type="primary" @click="importHandle" :disabled="this.isLoadingImport">导入</el-button>
          </el-upload> -->
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          ></el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            circle
            title="添加"
            @click="addHandle"
          ></el-button>
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs class="subnav_wrap" v-loading="tableLoading" style="min-height: 536px;">
          <singleTable
          :tableList='orderList' 
          :tableLeaderFieldsList='leaderFieldsList'
          :tableTailFieldsList="tailFieldsList"
          :tableFieldsList="fieldsList"
          @deleteHandle='deleteHandle'
          @editHandle='editHandle'
          @cloneHandle='cloneHandle'
          @checkHandle='checkHandle'
          ></singleTable>
        </el-tabs>
        <!-- <p v-if="this.orderList==''" style="text-align: center;margin:20px 0;">~~暂无数据~~</p> -->
        <div class="pagination_wrap fr">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            layout="sizes, total, prev, pager, next"
            :total="this.total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 编辑页 -->
    <div class="form_part clearfix" v-else>
      <div v-if="actionsType == 'check'">
        <virtualMaintainCheck :list="ruleForm" :uploadFileList="uploadFileList" @cancelHandle="resetForm"></virtualMaintainCheck>
      </div>
      <div v-else>
        <div class="base_wrap clearfix">
          <h2 class="title">产品基本信息</h2>
          <el-form :inline="true"  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-form-inline">
             <el-form-item label="产品编号" prop="productCode">
                <!-- @change="hasRepeatProductClick(ruleForm)"  @keyup.native="onkeyupProductCode($event)"  -->
              <el-input @change="hasRepeatProductClick(ruleForm)"  @keyup.native="onkeyupProductCode($event)"  v-model.trim="ruleForm.productCode"></el-input>
            </el-form-item> 
            
            <el-form-item label="系统编号">
              <span>{{ruleForm.productId}}</span>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model.trim="ruleForm.productName"></el-input>
            </el-form-item>
            <el-form-item label="业务分类" prop="businessType">
               <el-select  v-model.trim="ruleForm.businessType"  placeholder="请选择">
                  <el-option v-for="item in this.busiCategorytypelist"  :key="item.index" :value="item.index" :label="item.name"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="上市日期" prop="validDate">
              <el-date-picker
                    v-model.trim="ruleForm.validDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptionsStart"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="生命周期" prop="invalidDate">
                <el-date-picker
                    v-model.trim="ruleForm.invalidDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptionsEnd"
                    placeholder="选择日期">
                  </el-date-picker>
            </el-form-item>
             <el-form-item label="产品状态" prop="productStatus">
               <el-select  v-model.trim="ruleForm.productStatus"  placeholder="请选择">
                  <el-option v-for="item in this.statustypelist"  :key="item.index" :value="item.index" :label="item.name"></el-option>
               </el-select>
            </el-form-item>   
             <el-form-item label="产品分类" prop="productClassification" ref="typerules">
              <el-select  v-model.trim="ruleForm.productClassification" @change="departChange"  placeholder="请选择">
                  <el-option v-for="item in this.ProductClasstypelist"  :key="item.index" :value="item.index" :label="item.name"></el-option>
               </el-select>
            </el-form-item>
           <el-form-item label="是否活跃">
               <el-select  v-model.trim="ruleForm.active"  placeholder="">
                  <el-option v-for="i in this.ifStylelist"  :key="i.ifbatch" :value="i.ifbatch" :label="i.name"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="备注" prop="remarks" class="last">
              <el-input
                type="textarea"
                :autosize="{ minRows:4, maxRows: 2}"
                placeholder="多行输入"
                v-model="ruleForm.remarks"
                maxlength="255"
                show-word-limit>
              </el-input>
            </el-form-item>
 
          </el-form>
        </div>
        <div class="mt20 fr">
          <el-button class="mr10" @click="resetForm()">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')" :loading="this.loadingcommit" v-preventReClick>保存</el-button>
        </div>
      </div>
    </div>
    


    <!--删除-->
		<el-dialog title="提示" v-dialogDrag :visible.sync="dialogdelVisible" :close-on-click-modal="false" width="30%">
			<span>确定删除该条虚拟产品数据?</span>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogdelVisible = false">取 消</el-button>
		    <el-button type="primary"  @click="delVisibleclick()" v-preventReClick>确 定</el-button>
		  </span>
		</el-dialog>
    
     <!-- 对话框 -->
    <Dialog v-if="dialogVisible" :dialogDesc="dialogDesc" :dialogVisible="dialogVisible" @dialogConfirmHandle="dialogConfirmHandle" @dialogCancelHandle="dialogCancelHandle"></Dialog>

  </div>
</template>

<script>
import {exportProductFile} from "@/api/accessPermission.js";
import { 
	purchasegetuuid,
	virtualMaintainSearch,  
  virtualMaintainadd,     
	virtualMaintaindel,
	virtualMaintainView,
	virtualMaintainupd,	
  deleteUploadFile,
  cancelUploadFile,
  getCheckProductCode,
  getCheckProductBarcode
} from "@/api/module/masterDataApi.js";
import {
  materialStatusList,
  fabrictypedictionaries,
  getproProductCode,
  saveproProductCode,
} from "@/api/module/dictionariesApi.js";
import { getCheckSupplierName} from "@/api/module/materialsApi.js";
import { timeFormat,getTimeRange} from '@/utils/index.js';
import virtualMaintainCheck from '../virtualMaintainCheck/Index.vue';
import singleTable from "@/layouts/components/common/SingleTable.vue";
import Dialog from '@/layouts/components/common/Dialog.vue';
export default {
  name: "fabricMaintain",
  props: [],
  data() {
    return {
      myHeaders: {
                Authorization:'bearer '+this.$keycloak.token
      },
        // 对话框
      dialogVisible: false,  // 对话框是否可见
      dialogDesc: '',
      submitId: '',
      deleteId: '',
      deleteItemId:'',
      revokeId: '',
      closedId:'',
      revokeCode: '',

      popupTitle:"产品编号修改",
      uploadurlProduct:'',
      showfilelist:false,
      actionsType: '',
      updateUser:'',
      dateVal:'',
      startTime:'',
      endTime:'',
      // 上传字段
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      tableLoading: true,
      removeloading:false,
      errorProduct:true,
      errorMaterial:true,
      errorProductBarcode:true,
      isLoadingExport:false,
      isLoadingImport:false,
      dialogpopupVisible:false,
      itemProductCode:"",
      productId:'',
      popoverslist:[],
      fileType: '001',
      uploadFileList: [],
      headers: '',
      uploadUrl: process.env.VUE_APP_INFRASTRUCTURE_URL,
      // 上传字段 end
      productCode:'',
      dialogdelVisible:false,
      isEdit: false,
      loadingcommit:false,
      delid:'',
      updid:'',
      edittag:'',
      businessCode:'',
      brand:'',
      materialCode:'',
      season:'',     
      // form
      value1: null,
      suggestIndexTypelist:[
        {
          index:1,
          name:1
        },
         {
          index:2,
          name:2,
        },
        {
          index:3,
          name:3
        },
      ],
      ifStylelist:[
        {
          name:'是',
          index:'1',
          ifbatch:true
        },
        {
          name:'否',
          index:'2',
          ifbatch:false
        }
      ],
      rules: {
          productName: [
            { required: true, message: '请输入产品名称', trigger: 'blur' },
            { min:1, max:64, message: '超长字符', trigger: 'blur' }
          ],
          productCode:[
            { required: true, message: '请输入产品编号', trigger: 'blur' },
            { min:1, max:64, message: '超长字符', trigger: 'blur' },
          ],
          businessType:[
             { required: true, message: '请选择业务分类', trigger: 'change' }
          ],   
          validDate:[
            { type: 'string',required: true, message: '请输入上市日期', trigger: 'change' }
          ],
          invalidDate:[
            { type: 'string',required: true, message: '请输入生命周期', trigger: 'change' }
          ],             
          productClassification : [
            {required: true, message: '请选择产品分类', trigger:'change' }
          ],
          productStatus:[
             { required: true, message: '请选择产品状态', trigger: 'change'}
          ],
          remarks: [
            { min: 1, max: 255, message: '超长字符', trigger: 'blur' }
          ],
       },
      ruleForm:{
        	productCode:'',
        	productName:'',
        	validDate:'',
        	invalidDate:'',
          businessType:'',
        	productClassification:'',
        	productStatus:'1',
          active:true,
          remarks: '',
        	uuid:'',
          updateUser:'',
      },
      orderCode: '',
      startTime: '',
      endTime: '',
      customerId: '',
      orderStatus: '',
      purchaseOrderId: '',
      uuid: '',
      delteIndex:'',
      addItem:[],
      updItem:[],
      orderList: [],
      busiCategorytypelist:[],
			statustypelist:[],
			ProductClasstypelist:[],
      total: 0,
      pageSize: 10, //每页显示条数
      currentPage: 1, //页数
      ifStylelist:[
        {
          name:'是',
          index:'1',
          ifbatch:true
        },
        {
          name:'否',
          index:'2',
          ifbatch:false
        }
      ],
      leaderFieldsList: [
      ],
      fieldsList:[
        {
          name: '序号',
          field: 'idIndex',
          class: 'w10p',
          width: '150'
        },
        {
          name:'产品编号',
          field:'productCode',
          class: 'w10p',
          width: '150'
        },
        {
          name:'产品名称',
          field:'productName',
          class: 'w10p',
          width: '150'
        },
         {
          name:'业务分类',
          field:'businessTypeName',
          class: 'w10p',
          width: '150'
        },
        {
          name:'产品状态',
          field:'productStatus',
          proStatus:'proStatus',
          class: 'w10p',
          width: '150'
        },
         {
          name:'产品分类',
          field:'productClassification',
          productClassification:'productClassification',
          class: 'w10p',
          width: '150'
        },
         {
          name:'是否活跃',
          field:'activeName',
          class: 'w10p',
          width: '150'
        },
        {
          name:'更新人',
          field:'updateUser',
          class: 'w10p',
          width: '150'
        },
        {
          name:'更新时间',
          field:'updateTime',
          class: 'w10p',
          width: '180'
        }
      ],
      tailFieldsList:[
        {
          name: '操作',
          field: '',
          class: '',
          width: '200',
          minWidth: '10%',
          isAction: true,
          hasEdit: true,
          hasDelete: true,
          hasCopy:true,
          hasCheck:true,
        },
      ],
      pickerOptionsStart: {
          disabledDate: time => {
            let endDateVal = this.ruleForm.invalidDate;
            if (endDateVal) {
              return time.getTime() > new Date(endDateVal).getTime();
            }
          }
      },
      pickerOptionsEnd: {
          disabledDate: time => {
            let beginDateVal = this.ruleForm.validDate;
            if (beginDateVal) {
              return (
                time.getTime() <
                new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000
              );
            }
          }
      },
    };
  },
  components: {
    virtualMaintainCheck,
    singleTable,
    Dialog
  },
  beforeCreate() {

  },
  created() {

  },
  mounted() {
    this.orderStatus = '1';
	  this.getbusiCategory();
	  this.getProductStatus();
	  this.getProductClass();
    this.fun_date();
    this.headers = { Authorization: `Bearer ${this.$keycloak.token}` };
  },
  activated() {

  },
  watch: {
  
  },
  computed: {

  },
  methods: {
    dateValChange(){
      console.log(this.dateVal);
      this.searchHandle();
    },
     //固定展示为三个月前的时间范围
    fun_date(){
      this.dateVal=getTimeRange(-90);
      console.log(this.dateVal)
      this.searchHandle();
    },
    //导入
    importHandle(){
      this.uploadurlProduct=process.env.VUE_APP_SCMREPORT_URL +'reportservice/v1/importProduct'
      console.log(this.uploadurlProduct)
    },
    departChange(val) {
      console.log(val)    
    },
    //导出
    exportHandle(){
      console.log(this.idItems)
       this.tableLoading = true;
       this.isLoadingExport=true;
      let item=true;
      if(this.brand!=''){
           let obj={
              productCode:this.productCode,
						  materialCode:this.materialCode,
              season:this.season,
              businessType:"Retail,Suits",
              startTime: this.startTime,
              endTime: this.endTime,
              brand:this.brand,
              updateUser:this.updateUser
          }
           exportProductFile(JSON.stringify(obj)).then(res => {
             console.log(res);
              this.tableLoading = false;
              this.isLoadingExport=false;
              var blob = new Blob([res]);
              var url = window.URL.createObjectURL(blob);
              var aLink = document.createElement("a");
              aLink.style.display = "none";
              aLink.href = url;
              aLink.download = '现货面料产品主数据'+'.xlsx'; //下载后文件名
              document.body.appendChild(aLink);
              aLink.click();
              document.body.removeChild(aLink); //下载完成移除元素
              window.URL.revokeObjectURL(url); //释放掉blob对象
           });
      }else{
        this.$message({
          message: '请选择产品品牌按条件导出!',
          type: 'error'
        });
         this.tableLoading = false;
         this.isLoadingExport=false;
      }
    },
    beforeupload(file) {
//				 console.log(file)
         this.tableLoading = true;
         this.isLoadingImport=true;
				 var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
				 const isLt2M = file.size / 1024 / 1024 < 5     //这里做文件大小限制
					if(!isLt2M) {
						this.$message({
							message: '上传文件大小不能超过5MB!',
							type: 'error'
            });
            this.tableLoading = false;
            this.isLoadingImport=false;
					}
					return isLt2M
			},
    //订单导入成功之后
		UploadSuccess(response, file) {
				if(response.code == 0) {
					this.$message({
						message: '导入成功',
						type: 'success'
          });
          this.tableLoading = false;
          this.isLoadingImport=false;
					this.getPurchaseOrderList();
				}else{
					this.$message({
						message:response.message,
						type: 'error'
          });
          this.tableLoading = false;
          this.isLoadingImport=false;
				}
			},
    onkeyupProductCode(event){
      console.log(event)
      if(event.keyCode != 13){//除回车键外
            //标记当前事件函数的时间戳
            this.lastTimeStamp = event.timeStamp;
            setTimeout(() => {
                //1s后比较二者是否还相同（因为只要还有事件触发，lastTimeStamp就会被改写，不再是当前事件函数的时间戳）
                if(this.lastTimeStamp == event.timeStamp){
                  console.log(this.ruleForm.productCode)
                    var str=encodeURIComponent(this.ruleForm.productCode);
                    getCheckProductCode(str).then(res => {
                        if (res.code === '0') {
                            if(res.data!=''&&res.data!=null){
                              this.errorProduct=false;
                            }else{
                              this.errorProduct=true;
                            }
                          }
                     });
                }
            },800);
      }
    },

     //检验是否存在有重复的产品编码
    hasRepeatProductClick(item){
       getCheckProductCode(item.productCode).then(res => {
        if (res.code === '0') {
            if(res.data!=''&&res.data!=null){
              this.errorProduct=false;
              item.productCode='';
              this.$message({
                message: '该产品编号在面料产品/成品产品/虚拟产品数据中已存在！',
                type: 'error'
              });
              return false;
            }else{
              this.errorProduct=true;
            }
        }
      });
    },
    //检验是否存在有重复的产品条形码
    onkeyupProductBarcode(event){
       if(event.keyCode != 13){//除回车键外
            //标记当前事件函数的时间戳
            this.lastTimeStamp = event.timeStamp;
            setTimeout(() => {
                //1s后比较二者是否还相同（因为只要还有事件触发，lastTimeStamp就会被改写，不再是当前事件函数的时间戳）
                if(this.lastTimeStamp == event.timeStamp){
                    var str=encodeURIComponent(this.ruleForm.productBarcode);
                    getCheckProductBarcode(str).then(res => {
                      if (res.code === '0') {
                        if(res.data!=''&&res.data!=null){
                            this.errorProductBarcode=false;

                          }else{
                            this.errorProductBarcode=true;
                          }
                      }
                    });
                }
            },800);
      }
    },
    //检验是否存在有重复的产品条形码
    hasRepeatProductBarcodeClick(item){
       getCheckProductBarcode(item.productBarcode).then(res => {
        if (res.code === '0') {
           if(res.data!=''&&res.data!=null){
              this.errorProductBarcode=false;
              item.productBarcode='';
              this.$message({
                message: '该产品条形码在面料产品或成品产品数据中已存在',
                type: 'error'
              });
              return false;
            }else{
              this.errorProductBarcode=true;
            }
        }
      });
    }, 
    
    //检验是否存在有重复的物料编码
     onkeyupMaterialCode(event){
      console.log(event)
      if(event.keyCode != 13){//除回车键外
            //标记当前事件函数的时间戳
            this.lastTimeStamp = event.timeStamp;
            setTimeout(() => {
                //1s后比较二者是否还相同（因为只要还有事件触发，lastTimeStamp就会被改写，不再是当前事件函数的时间戳）
                if(this.lastTimeStamp == event.timeStamp){
                    var str=encodeURIComponent(this.ruleForm.materialCode);
                     getCheckSupplierName(str).then(res => {
                      if (res.code === '0') {
                        console.log(res.data);
                        if(res.data==null){
                          this.errorMaterial=false;
                        }else{
                            this.errorMaterial=true;
                        }
                      }
                    });
                }
            },800);
      }
    },
      //检验是否存在有重复的物料编码
    hasRepeatMeterialClick(item){
      getCheckSupplierName(item.materialCode).then(res => {
        if (res.code === '0') {
          if(res.data==null){
              this.errorMaterial=false;
              item.materialCode='';
            	this.$message({
                message: '该物料编号在面料物料数据中不存在',
                type: 'error'
              }); 
          }else{
            this.errorMaterial=true;
          }
        }
      });

    },
    
    // 上传
    handleUploadRemove(file,list,index) {
      // 删除缩略图
         console.log(file.uuid)
         if (!file.response) {
          if(this.isEditor==true){
            this.updItem.push(file.id);
            console.log(this.updItem)
          }
          this.uploadFileList = this.uploadFileList.filter(item => item.id != file.id);
          console.log(this.uploadFileList)
        } else {
          console.log('elseelseelse')
          console.log(this.uploadFileList)
          deleteUploadFile(file.response.data.id).then(res => {
            if (res.code === '0') {
              this.uploadFileList = this.uploadFileList.filter(item => item.uid != file.uid);
              console.log(this.uploadFileList)
            }
          });
        }
        console.log(this.uploadFileList)
      
    },
    handleUploadSuccess(response, file, fileList) {
      console.log('上传成功：', response, file, fileList);
       this.uploadFileList=fileList;
       if(this.isEditor==true){
         this.addItem.push(file.response.data.id);
         console.log(this.addItem)
       }

    },
     handleExceed(files, fileList) {
        this.$message.error(`最多上传10张图片!`);
     },
    beforeUploadUpload(file) {
      let isType = false;
      let fileName = file.name;
      let regex = /(.jpg|.png|.gif|.jpeg)$/;
      if (regex.test(fileName.toLowerCase())) {
        isType = true;
      } else {
        this.$message.error('上传的图片只能是 JPG/PNG/GIF/JPEG/ 格式!');
      }
      return isType;
    },
    // 上传 end

  	//业务分类
    getbusiCategory(){
      fabrictypedictionaries('ProductBusinessType').then(response => {
				if(response.code === "0") {		
          if(response.data!=''){
            const arr1 = response.data.filter(item =>item.name=='订货')
            console.log(arr1)
            this.busiCategorytypelist=arr1;
          }						
				}
			});
  	},
  	//产品状态
  	getProductStatus(){
  		this.statustypelist = materialStatusList().data;
  	},
  	//产品属性
  	async getProductClass(){
      // fabrictypedictionaries('ProductClassifcationType').then(response => {
			// 	if(response.code === "0") {					
			// 		this.ProductClasstypelist = response.data;
			// 	}
			// });
      let res = await fabrictypedictionaries("ProductClassifcationType")
      this.ProductClasstypelist = res.data;
  	},

    oninput(num) {
      console.log(num)
      if(num!=null){
        	var str = num
          var len1 = str.substr(0, 1)
          var len2 = str.substr(1, 1)
          var lenLast=str.substr(str.length-1)
          //如果第一位是0，第二位不是点，就用数字把点替换掉
          if (str.length > 1 && len1 == 0 && len2 != ".") {
            str = str.substr(1, 1)
          }
          //第一位不能是.
          if (len1 == ".") {
            str = ""
          }
          //最后一位不能是.
          if (str.length==8&&lenLast == ".") {
            str = ""
          }
          //限制只能输入一个小数点
          if (str.indexOf(".") != -1) {
            var str_ = str.substr(str.indexOf(".") + 1)
            if (str_.indexOf(".") != -1) {
              str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
            }
          }
          //正则替换
          str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
          str = str.replace(/\.\d\d\d\d\d\d$/,'') // 小数点后只能输两位
          return str
      }
		
		},
  	// 过滤输入的数字
    InputNumber(property) {
      this.ruleForm[property] = this.limitInputPointNumber(this.ruleForm[property]);
    },
    // 限制只能输入数字(可以输入两位小数)
    limitInputPointNumber(val) {
      console.log(val);
      if (val === 0 || val === "0" || val === "") {
        return "";
      } else {
        let value = null;
        // value = String(val).replace(/[^\d]/g, ""); // 清除“数字”以外的字符
        value = value.replace(/\.{2,}/g, "."); // 只保留第一个. 清除多余的
        value = value.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
		    value = value.replace(/\.\d\d\d$/,'') // 小数点后只能输两位
        value = value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        console.log(value);
        return Number(value);
      }
    },
    // from
    submitForm(formName) {
    	 this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.loadingcommit=true;
            console.log(this.ruleForm)
            console.log('产品编码',this.errorProduct)        
            if(this.edittag=='add'){
              console.log(this.ruleForm)
              this.ruleForm.updateUser = this.$store.state.realName;
              virtualMaintainadd(this.ruleForm).then(response => {
                if(response.code === "0") {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  });
                  this.loadingcommit=false;
                  this.isEdit = false;
                  this.getPurchaseOrderList();
                }else{
                    this.loadingcommit=false;
                }
                setTimeout(() => {
                  this.loadingcommit=false;
                }, 3000);
              });
            }else{
              this.ruleForm.updateUser = this.$store.state.realName;
              this.ruleForm.deleteAttachmentsIds=this.updItem;
              virtualMaintainupd(this.ruleForm, this.updid).then(response => {
                if(response.code === "0") {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                  this.loadingcommit=false;
                  this.isEdit = false;
                  this.updItem=[];
                  this.addItem=[];
                  this.getPurchaseOrderList();
                }else{
                    this.loadingcommit=false;
                }
                setTimeout(() => {
                  this.loadingcommit=false;
                },3000);
              });
            } 
            
          } else {
            console.log('error submit!!');
            return false;
          }
       });
    	
    },
    //取消
    resetForm() { 
      if(this.addItem!=''){
         cancelUploadFile(this.addItem).then(res => {
            if (res.code === '0') {
             this.addItem=[];
            }
          });
      }
    	this.isEdit = false;
      this.uploadFileList=[];
      this.updItem=[];
      this.getPurchaseOrderList();
    },
    searchHandle() {
      // 搜索
      this.currentPage=1;
      this.purchaseOrderId = this.orderCode;
      if (this.dateVal) {
        this.startTime = this.dateVal[0];
        this.endTime = this.dateVal[1];
      } else {
        this.startTime = '';
        this.endTime = '';
      }
      this.getPurchaseOrderList();
    },
    addHandle() {
      // 添加
      this.isEdit = true;
      this.isEditor=false;
      this.actionsType = 'add';
      this.edittag='add';
      this.uploadFileList=[];
      this.ruleForm={
        productStatus:'1',
        active:true,
        uuid:''
      }
      purchasegetuuid().then(response => {
				if(response.code === "0") {
					this.ruleForm.uuid = response.data;
				}
			});
    },
    editHandle(index,item) {
      // 编辑项
      this.isEdit = true;
      this.isEditor=true;
      this.actionsType = 'edit';
      this.edittag='updated';
      this.updid=item.id;
      virtualMaintainView(item.id).then(response => {
        if(response.code === "0") {
          this.ruleForm=response.data;
          this.ruleForm.productStatus=response.data.productStatus.toString();
          this.ruleForm.validDate = response.data.validDate ? timeFormat(response.data.validDate).split(' ')[0] : '';
          this.ruleForm.invalidDate= response.data.invalidDate ? timeFormat(response.data.invalidDate).split(' ')[0] : '';
        }
      });
    },
    editScopeWidth(res,val,msg,start,end){
      console.log(res,val,msg,start,end)
       if((val.indexOf("-") != -1)==true){
          var str = val.split('-');
          console.log(str)
            if(str[0]!='undefined'){
              res.start=str[0];
            }else{
              res.start='';
            }
            if(str[1]!='undefined'){
              res.end=str[1];
            }else{
             res.end='';
            }
        }else{
          res.start=response.data.msg;
          res.end='';
        }
        console.log(res)
    },
    editScope(res,val,msg,start,end){
      console.log(res,val,msg,start,end)
       if((val.indexOf("-") != -1)==true){
          var str = val.split('-');
          console.log(str)
            if(str[0]!='undefined'){
              res.start=str[0];
            }else{
              res.start='';
            }
            if(str[1]!='undefined'){
              res.end=str[1];
            }else{
             res.end='';
            }
        }else{
          res.start=response.data.msg;
          res.end='';
        }
        console.log(res)
    },
    deleteHandle(index,item) {
      // 删除项
      this.delid=item.id;
      this.dialogdelVisible = true;
    },
    //删除确定
    delVisibleclick(){
      this.removeloading=true;
    	virtualMaintaindel(this.delid).then(response => {
					if(response.code === "0") {
						this.$message({
							message:'删除成功！',
							type: 'success'
            });
            this.removeloading=false;
						this.dialogdelVisible = false;
						this.getPurchaseOrderList();
					}
				});
    },
    cloneHandle(item) {
       this.isEdit = true;
       this.isEditor=false;
       this.actionsType = 'add';
    	 this.edittag='add';
      // 克隆
      virtualMaintainView(item.id).then(response => {
        if(response.code === "0") {
          this.ruleForm=response.data;
          this.ruleForm.productStatus=response.data.productStatus.toString();
          this.ruleForm.validDate = response.data.validDate ? timeFormat(response.data.validDate).split(' ')[0] : '';
          this.ruleForm.invalidDate= response.data.invalidDate ? timeFormat(response.data.invalidDate).split(' ')[0] : '';
          this.getuuid();
        }
      });
    },
    checkHandle(index,item) {
      // 查看详情
      console.log('查看详情模板：', item);
      this.actionsType = 'check';
      this.edittag = 'updated';
      this.isEdit = true;
      this.isEditor=false;
      // this.ruleForm = item;
      virtualMaintainView(item.id).then(response => {
        if(response.code === "0") {
          this.ruleForm = response.data;
          this.ruleForm.productStatus=response.data.productStatus.toString();
          this.ruleForm.validDate = response.data.validDate ? timeFormat(response.data.validDate).split(' ')[0] : '';
          this.ruleForm.invalidDate= response.data.invalidDate ? timeFormat(response.data.invalidDate).split(' ')[0] : '';
        }
      });
    },
    getuuid(){
    	purchasegetuuid().then(response => {
        if(response.code === "0") {
          this.ruleForm.uuid = response.data;
        }
      });
    },
    popoverHandle(item) {
      console.log(this.ruleForm)
      this.dialogpopupVisible=true;
      this.itemProductCode=item.productCode;
      this.productId=item.interiorCode;
          // 物料编码
          getproProductCode(item.productCode).then(res => {
            if (res.code === "0") {
              this.popoverslist = res.data.SCM;
            }
          });
    },
    savepopoverHandle() {
       // 物料编码
       if(!this.ruleForm.productCode){
         this.$message({
            type: 'error',
            message: '请输入产品编号！'
          });
          return false;
       }else{
          let newProductCode=this.ruleForm.productCode;
          saveproProductCode(this.itemProductCode,this.ruleForm.productCode,this.productId).then(res => {
            if (res.code === "0") {
              this.$message({
                type: 'success',
                message: '保存成功！'
              });
              this.dialogpopupVisible=false;
              
              this.$nextTick(()=>{
                 this.ruleForm.productCode=newProductCode;
              })
            }
          });
       }
      
    },
    cancelpopoverHandle() {
      this.ruleForm.productCode=this.itemProductCode
      this.dialogpopupVisible=false;
      
    },
    closeDialog(){
      this.ruleForm.productCode=this.itemProductCode;
    },
    getPurchaseOrderList() {
    	this.tableLoading = true;
      // 虚拟产品请求列表
      let data = {
              productCode:this.productCode,
              createTime: this.startTime,
              endTime: this.endTime,
              updateUser:this.updateUser,
              businessType:'Agency'
      };
      virtualMaintainSearch(this.currentPage, this.pageSize, data).then(res => {
        console.log('虚拟产品订单列表：', res);
        if (res.code == '0') {
        	this.tableLoading = false;
          this.orderList = res.data.records;
          this.total = res.data.total;
          this.orderList.forEach((item, index) => {
            if (item.updateTime) {
              item.updateTime = timeFormat(item.updateTime);
            } else {
              item.updateTime = "空";
            }
            if(item.active==true){
              item.activeName='是';
            }else{
              item.activeName='否';
            }
            if(item.businessType=='Agency'){
             item.businessTypeName='订货'
            }
          });
          for(var i=0;i<this.orderList.length;i++){
            let item=this.orderList[i];
            if(this.currentPage!=1){
              item.idIndex=(this.currentPage-1)*this.pageSize+(i+1)
            }else{
              item.idIndex=i+1;
            }
          	for(var a=0;a<this.ProductClasstypelist.length;a++){
          		let dataa=this.ProductClasstypelist[a];
          		if(item.productClassification==dataa.index){
                item.productClassificationName=dataa.name;
                 this.$forceUpdate();
          		}
          	}
            
          }
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPurchaseOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPurchaseOrderList();
    },
    

  }
};
</script>

<style>
.subnav_wrap .el-tabs__item {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
  vertical-align: baseline !important;
}
.subnav_wrap.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}
.subnav_wrap .el-tabs__content {
  overflow: visible;
}
.subnav_wrap .checkbox_list .el-checkbox__label {
  width: 100%;
  padding-left: 0;
  color: #606266;
}
.subnav_wrap .checkbox_list .el-checkbox {
  width: 100%;
}
.subnav_wrap .checkbox_list .el-checkbox__input {
  margin-right: 10px;
}
.subnav_wrap ul.el-dropdown-menu li.el-dropdown-menu__item {
  padding: 0 10px;
}

.fabricMaintain .el-form-item__label {
  font-size: 14px;
  color: #606266;
  font-weight: normal;
}
.fabricMaintain .el-form-item__content {
  width: auto;
}
.fabricMaintain .el-input {
  width: auto;
}
.fabricMaintain .whole_long .el-form-item__content {
  width: 65%;
}
.fabricMaintain .whole_long .el-input {
  width: 100%;
}
.fabricMaintain .el-rate {
  height: 40px;
  line-height: 40px;
  vertical-align: top;
}
.fabricMaintain .el-rate__item {
  vertical-align: baseline;
}
.el-form-item {
  width: 43%;
}
.fabricMaintain .graphic_wrap .el-form-item {
  width: 100%;
}
.fabricMaintain .graphic_wrap .el-form-item__content {
  width: 60% !important;
}
.fabricMaintain  .el-form-item__content .el-upload-list__item > div {
  height: 100%;
}
.fabricMaintain  .el-form-item__content .el-upload-list__item .el-upload-list__item-thumbnail {
  width: auto;
  height: 100%;
}
.fabricMaintain .el-upload--picture-card i.el-icon-plus {
  font-size: 30px !important;
}
.fabricMaintain i.el-icon-download {
  font-size: 20px !important;
}
.fabricMaintain .el-form-item i.el-icon-delete {
  margin-left: 0;
  color: #fff;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.fabricMaintain .el-form-item.last {
    width: 94%!important;
}
.fabricMaintain .el-form-item.photo{
	width: 94%!important;
}
.fabricMaintain .el-form-item.photo .el-form-item__content{
	width:66%;
}
.fabricMaintain .el-form-item.last .el-form-item__content{
	width:66%;
}
.fabricMaintain .el-form-item.limit{
  width: auto;
  margin-right: 0px;
}
.fabricMaintain form.el-form.demo-form-inline .el-form-item .el-form-item__content .unlimit.el-input input.el-input__inner{
  width:100%!important;
}
</style>